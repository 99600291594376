/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { H4, Button, Flex } from '../../lib/components'
import { MD } from '../../lib/utils/constants'
import { useBreakpoint } from '../../lib/utils/hooks'
import { EngagementEvents, logCrmEvent } from '../../lib/utils/crmEvents'
import { RootState } from '../../store'
import { Link } from 'react-router-dom'
import { kycServices } from '../../services'
import './investment-details.scss'

const LegalAgreement = ({
  setAgreement,
  getInvestmentDocument,
  getSignedDocument,
  getDocumentAccessToken,
  getDocumentSigningLink,
  getDocumentDownloadLink,
  agreementLink,
  acceptTOBCheckbox,
  setAcceptTOBCheckbox,
  project,
}: any) => {
  const POLL_STATUS_INTERVAL = 3000 //ms
  const { authToken, userId } = useSelector((state: RootState) => state.investor)
  const [accessToken, setAccessToken] = useState('')
  const [documentId, setDocumentId] = useState('')
  const [isButtonDisabled, setButtonDisable] = useState(false)
  const [buttonText, setButtonText] = useState('Sign Agreement')
  const [isSentForSigning, setSentForSigning] = useState(false)
  const { breakpoint } = useBreakpoint()

  const handleCheckboxClick = async () => {
    const data: any = await kycServices.acceptTermsOfBusinessAgreement(
      { declaration: true },
      {
        Authorization: authToken ? authToken : '',
      },
    )

    if (data && data.data && data.status === 200) {
      setAcceptTOBCheckbox(true)

      logCrmEvent(
        EngagementEvents.ACCEPT_TERMS_OF_BUSINESS,
        {
          Authorization: authToken ? authToken : '',
        },
        {
          opportunityName: project?.projectName,
          opportunityId: project?.id,
        },
      )
    }
  }

  async function handleAgreementBtnClick() {
    try {
      if (!isSentForSigning) {
        setButtonDisable(true)
        setButtonText('Signing in progress..')

        const { signingLink, documentId } = await getDocumentSigningLink(accessToken)
        setDocumentId(documentId)

        window.open(signingLink)

        setButtonText('Download Agreement')
        setSentForSigning(true)
      } else {
        if (buttonText === 'Download Agreement') {
          setButtonDisable(true)
          const downloadLink = await getDocumentDownloadLink(accessToken, documentId)

          window.open(downloadLink)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    ;(async () => {
      const existingDocument = await getSignedDocument()

      if (existingDocument) {
        setSentForSigning(true)
        setButtonDisable(false)
        setButtonText('Download Agreement')
        setDocumentId(existingDocument.document_id)
        setAgreement(existingDocument.document_url)
      }

      const accessToken = await getDocumentAccessToken()
      setAccessToken(accessToken)
    })()
  }, [])

  useEffect(() => {
    if (!agreementLink || !acceptTOBCheckbox) {
      const intervalID = setInterval(async () => {
        const response: any = await kycServices.getKYC_v1(userId.toString(), {
          Authorization: authToken ? authToken : '',
        })
        if (!!response && response.data && response.status === 200) {
          if (response.data.accept_tob) setAcceptTOBCheckbox(true)
        }

        const existingDocument = await getSignedDocument()
        if (existingDocument?.document_id) {
          const document = await getInvestmentDocument(existingDocument.document_id)

          if (document?.status === 'COMPLETED') {
            setButtonText('Download Agreement')
            setAgreement(document.document_url)
            setButtonDisable(false)

            logCrmEvent(
              EngagementEvents.CLIENT_AGREEMENT_SIGNED,
              {
                Authorization: authToken ? authToken : '',
              },
              {
                opportunityName: project?.projectName,
                opportunityId: project?.id,
              },
            )
          }
        }
      }, POLL_STATUS_INTERVAL)

      return () => clearInterval(intervalID)
    }
  }, [agreementLink, acceptTOBCheckbox])

  return (
    <>
      <div className='legal-agreement-wrapper'>
        <H4
          text={
            agreementLink && acceptTOBCheckbox
              ? 'You have accepted the Terms of Business and Client Agreement. Please continue.'
              : 'To proceed, please accept our Terms of Business and Client Agreement.'
          }
        />
        {!isSentForSigning && !agreementLink && (
          <span className='basic agreement-disclaimer'>
            Click the button below to review and sign the Client Agreement.
          </span>
        )}
        {agreementLink && (
          <span
            className={`basic agreement-disclaimer ${
              breakpoint && breakpoint <= MD ? 'text-center' : ''
            }`}
          >
            Your agreement has been successfully signed. You can download the agreement by clicking
            the below button.
          </span>
        )}
        {isSentForSigning && !agreementLink && (
          <span className='basic agreement-disclaimer'>Please complete the e-signing process.</span>
        )}
        <div>
          <Button
            classes={breakpoint && breakpoint > MD ? ['auto-btn-width'] : ['']}
            iconVariant='left'
            text={buttonText}
            isDisabled={isButtonDisabled}
            handleClick={handleAgreementBtnClick}
            variant='basic-btn'
          />
        </div>

        <Flex>
          <input
            className='accept-tob'
            type='checkbox'
            checked={acceptTOBCheckbox}
            onChange={handleCheckboxClick}
            disabled={acceptTOBCheckbox}
          />
          <span className='basic ml-8'>
            I confirm that I have read and accepted the{' '}
            <Link className='accept-tob-link' to={'/terms-of-business'} target='_blank'>
              Terms of Business
            </Link>
            .
          </span>
        </Flex>
      </div>
    </>
  )
}

export { LegalAgreement }
