import React from 'react'

import {
  H2,
  Span,
  Flex,
  LeftArrowIcon,
  Anchor,
  Button,
  BasicStepper,
  CTABanner,
  InvestmentEssentials,
  ContactUsModal,
  TransactionChecklist,
} from '../../lib/components'
import { InvestmentAmount } from './investment-amount'
import {
  InvestFormStep,
  QUESTION_FOR_PROJECT,
  GET_IN_TOUCH_WITH_OWNER,
  CONTACT_US,
} from '../../lib/utils/message'
import { PaymentUploadDocument } from '../payment/upload-document'
import { LegalAgreement } from './legal-agreement'
import { MD } from '../../lib/utils/constants'
import { useBreakpoint } from '../../lib/utils/hooks'
import PopupBlockedWarningModal from '../../lib/components/PopupBlockedWarningModal/popup-blocked-warning-modal'

const InvestPresenter = ({
  backToProjectHandler,
  currentStep,
  handleCurrentStep,
  project,
  userInvestmentDetail,
  investmentValue,
  setInvestmentValue,
  investmentData,
  handleDisableButton,
  handleInvestmentAmount,
  handleFileUpload,
  handleCompletePayment,
  navigateToPaymentSuccessScreen,
  handleUploadAgreement,
  handleAgreement,
  completedStep,
  setIsApiError,
  agreementLink,
  showContactUsModal,
  handleShowContactUsModal,
  setAgreement,
  getInvestmentDocument,
  getSignedDocument,
  getDocumentAccessToken,
  getDocumentSigningLink,
  getDocumentDownloadLink,
  showInvestmentEssentialsModal,
  setInvestmentEssentialsModal,
  isTransactionChecklistChecked,
  setIsTransactionChecklistChecked,
  showTransactionChecklistModal,
  setTransactionChecklistModal,
  acceptTOBCheckbox,
  setAcceptTOBCheckbox,
  showPopupBlockedModal,
}: any) => {
  // TODO replace any with a type for easier understanding
  const { breakpoint } = useBreakpoint()
  const handleInvestorHeading = (val: number): string => {
    if (val === 1) return 'Choose your investment amount'
    if (val === 2) return 'General Agreement'
    if (val === 3) return 'Payment Upload'
    return ''
  }
  return (
    <>
      <Flex>
        <Button
          classes={['auto-small-btn-width']}
          variant='secondary-btn'
          handleClick={backToProjectHandler}
          IconComponent={<LeftArrowIcon />}
        />
        <Span classes={['ml-16', 'font-16', 'mb-32']} variant='bold' text={'Back to Opportunity'} />
      </Flex>
      <div className='invest-header'>
        <div className='invest-header-heading'>
          <H2 text={handleInvestorHeading(currentStep)} />
        </div>
        <div className='invest-header-subheading'>
          <div>
            <Span
              text={currentStep === 1 ? 'Summary of your investment for' : 'Investment process for'}
            />
            <Anchor
              classes={['invest-header-project-title']}
              text={(project && project?.projectName) || ''}
              href={`/opportunity/${project?.projectSlug}/${project?.id}`}
              variant='basic-anchor'
            />
          </div>
          <div>
            <div
              data-testid='risk-details-modal-open-link'
              className='modal-opener-link'
              onClick={() => setInvestmentEssentialsModal(true)}
            >
              View Investment Risk Details
            </div>
            <div
              data-testid='transaction-checklist-modal-open-link'
              className='mt-8 modal-opener-link'
              onClick={() => setTransactionChecklistModal(true)}
            >
              View Important Payment Checklist
            </div>
          </div>
        </div>
      </div>
      <BasicStepper activeStep={currentStep} steps={InvestFormStep} />
      {currentStep === 1 && (
        <InvestmentAmount
          project={project}
          userInvestmentDetail={userInvestmentDetail}
          investmentValue={investmentValue}
          setInvestmentValue={setInvestmentValue}
          handleShowContactUsModal={handleShowContactUsModal}
          handleDisableButton={handleDisableButton}
          handleInvestmentAmount={handleInvestmentAmount}
          setIsApiError={setIsApiError}
          investmentData={investmentData}
        />
      )}
      {currentStep === 2 && (
        <LegalAgreement
          project={project}
          handleUploadAgreement={handleUploadAgreement}
          handleFileUpload={handleFileUpload}
          investmentData={investmentData}
          agreementLink={agreementLink}
          setAgreement={setAgreement}
          getDocumentDownloadLink={getDocumentDownloadLink}
          getInvestmentDocument={getInvestmentDocument}
          getSignedDocument={getSignedDocument}
          getDocumentAccessToken={getDocumentAccessToken}
          getDocumentSigningLink={getDocumentSigningLink}
          acceptTOBCheckbox={acceptTOBCheckbox}
          setAcceptTOBCheckbox={setAcceptTOBCheckbox}
        />
      )}
      {currentStep === 3 && investmentData && project && (
        <PaymentUploadDocument
          project={project}
          investment={investmentData}
          handleFileUpload={handleFileUpload}
          handleCompletePayment={handleCompletePayment}
        />
      )}
      <br></br>
      {currentStep !== 3 && currentStep !== 1 && (
        <div className='stepper-btn-container'>
          <Button
            classes={breakpoint && breakpoint > MD ? ['auto-btn-width'] : ['']}
            text={currentStep === 1 ? 'Cancel' : 'Back'}
            isDisabled={completedStep === 3}
            variant='basic-btn'
            handleClick={() => {
              setIsApiError(false)
              currentStep > 1 && handleCurrentStep(currentStep - 1)
            }}
          />
          <Button
            classes={breakpoint && breakpoint > MD ? ['auto-btn-width'] : ['']}
            text={currentStep === InvestFormStep.length ? 'Finish' : 'Continue'}
            variant='primary-btn'
            isDisabled={handleDisableButton()}
            handleClick={() => {
              if (currentStep < InvestFormStep.length)
                if (currentStep === 1) handleInvestmentAmount()
              if (currentStep === 2) handleAgreement()
              if (currentStep === 3) navigateToPaymentSuccessScreen()
            }}
          />
        </div>
      )}
      <div>
        <Flex variant='row-center' classes={['mt-88']}>
          <CTABanner
            heading={QUESTION_FOR_PROJECT}
            subHeading={GET_IN_TOUCH_WITH_OWNER}
            ButtonComponent={
              <Button
                classes={['auto-btn-width']}
                variant='primary-btn'
                text={CONTACT_US}
                handleClick={handleShowContactUsModal}
              />
            }
          />
        </Flex>
      </div>
      {showContactUsModal ? (
        <ContactUsModal
          active={true}
          subject={'Inquiry'}
          handleOnClose={handleShowContactUsModal}
        />
      ) : null}
      {showInvestmentEssentialsModal ? (
        <InvestmentEssentials
          opportunity={project}
          active={showInvestmentEssentialsModal}
          handleOnClose={() => setInvestmentEssentialsModal(false)}
        />
      ) : null}
      <TransactionChecklist
        active={
          showTransactionChecklistModal || (!isTransactionChecklistChecked && currentStep >= 2)
        }
        handleOnClose={() => {
          setTransactionChecklistModal(false)
          setIsTransactionChecklistChecked(true)
        }}
        opportunity={project}
      />

      {/* Modal to show popup block warning */}
      {showPopupBlockedModal && <PopupBlockedWarningModal />}
    </>
  )
}
export { InvestPresenter }
