/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useRef, useEffect } from 'react'
import './accept-nda.scss'
import { AcceptNDAPresenter } from './accept-nda-presenter'
import { useNavigate } from 'react-router-dom'
import { kycServices } from '../../services'
import { updateConfiguration } from '../../global-state/investor-slice'
import { useDispatch, useSelector } from 'react-redux'
import { showBanner } from '../../global-state/banner-slice'
import { RootState } from '../../store'
import { getPreviousUrl } from '../../lib/utils/helpers'
import { EngagementEvents, logCrmEvent } from '../../lib/utils/crmEvents'

const AcceptNDAContainer = () => {
  const [expand, setExpand] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [acceptedTNC, setAcceptedTNC] = useState(false)
  const [acceptedNDA, setAcceptNDA] = useState(false)
  const [isButtonDisabled, setButtonDisabled] = useState(true)

  const { authToken } = useSelector((state: RootState) => state.investor)

  const scrollRef = useRef<null | HTMLDivElement>(null)
  useEffect(() => {
    dispatch(
      showBanner({
        text: 'Please accept the Confidentiality Agreement to view the opportunity details',
        variant: 'warning-banner',
      }),
    )
  }, [])
  const handleButtonClick = () => {
    setExpand(true)
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }
  const handleChange = (field: string, val: boolean) => {
    if (field === 'acceptedNDA') setAcceptNDA(val)
    if (field === 'acceptedTNC') setAcceptedTNC(val)
  }

  const handleSelfDeclarationSubmit = async () => {
    const data: any = {
      acceptedTNC: true,
      acceptedNDA: true,
    }
    try {
      const response: any = await kycServices.acceptTNCAndNDA_v1(data, {
        authorization: authToken ? authToken : '',
      })
      if (response && response.status === 200) {
        dispatch(
          updateConfiguration({
            name: 'acceptedNDA',
            value: response.data.is_nda_accepted,
          }),
        )
        dispatch(
          updateConfiguration({
            name: 'kycStatus',
            value: response.data.status,
          }),
        )
        dispatch(
          showBanner({
            text: 'You can now view the opportunity details',
            variant: 'success-banner',
          }),
        )

        logCrmEvent(EngagementEvents.ACCEPTED_CONFIDENTIALITY_AGREEMENT, {
          Authorization: authToken ?? '',
        })

        navigate(getPreviousUrl(), { replace: true })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (expand && acceptedTNC && acceptedNDA) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [expand, acceptedNDA, acceptedTNC])
  return (
    <AcceptNDAPresenter
      isButtonDisabled={isButtonDisabled}
      handleChange={handleChange}
      handleButtonClick={handleButtonClick}
      ref={scrollRef}
      expand={expand}
      acceptedNDA={acceptedNDA}
      acceptedTNC={acceptedTNC}
      handleSelfDeclarationSubmit={handleSelfDeclarationSubmit}
    />
  )
}

export { AcceptNDAContainer }
