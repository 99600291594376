/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from '../../store'
import { OpportunityDetailPresenter } from './opportunity-detail-presenter'
import { ProjectCardProps, SearchParams } from '../../lib/types'
import { projectServices, investServices, kycServices } from '../../services'
import { showBanner } from '../../global-state/banner-slice'
import { calculateRemainingDays, displayDollar } from '../../lib/utils/helpers'
import { Anchor } from '../../lib/components'
import { updateConfiguration } from '../../global-state/investor-slice'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
import { EVENTS } from '../../lib/utils/constants'
import { EngagementEvents, logCrmEvent, updateCrm } from '../../lib/utils/crmEvents'

const OpportunityDetailContainer = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const contactUsRef = useRef<any>(null)
  const { id, projectSlug } = params
  const { authToken, kycStatus, userId } = useSelector((state: RootState) => state.investor)
  const [opportunity, setOpportunity] = useState<ProjectCardProps>()
  const [similarProjects, setSimilarProjects] = useState<ProjectCardProps[]>([])
  const [isSaveForLater, setIsSaveForLater] = useState(false)
  const searchParams: SearchParams = {
    limit: '4',
    offset: '0',
    sortBy: 'CLOSING_DATE_DESC',
  }
  const [userInvestmentDetail, setUserInvestmentDetail] = useState<any>([])
  const [userInvestmentLatest, setUserInvestmentLatest] = useState<any>([])
  const [showContactusModal, setContactUsModal] = useState(false)
  const [investButtonProperty, setInvestButtonProperty] = useState({
    buttonText: 'Invest',
    isDisable: false,
    kycStatus: kycStatus || 'PENDING',
    investmentInfo: <></>,
  })

  const fetchSimilarProjects = (projectId: number, assetClass: string) => {
    const params = {
      ...searchParams,
      filterBy: assetClass,
    }
    projectServices
      .getSearchProject(params, {
        Authorization: authToken ? authToken : '',
      })
      .then((data) => {
        if (data.status === 200 && data.projects) {
          const filtered = data.projects.filter((proj: any) => projectId !== proj.id)
          setSimilarProjects(filtered.slice(0, 3))
        }
      })
  }

  const saveForLaterHandleClick = async () => {
    const data: any = await projectServices.postSaveProject(id as string, {
      Authorization: authToken ? authToken : '',
    })
    if (data.status === 200) {
      setIsSaveForLater((preState) => !preState)
      if (!isSaveForLater) {
        dispatch(
          showBanner({
            text: 'Opportunity saved for later',
            variant: 'success-banner',
          }),
        )
      }
      if (isSaveForLater) {
        dispatch(
          showBanner({
            text: 'Opportunity removed from saved for later list',
            variant: 'success-banner',
          }),
        )
      }
    } else
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
  }

  const investHandleClick = (projectSlug: string, id: number) => {
    if (kycStatus) {
      if (kycStatus !== 'APPROVED') {
        navigate('/profile/kyc')
        return
      } else {
        if (userInvestmentLatest) {
          if (userInvestmentLatest.status === 'INITIATED') {
            // dispatch(showBanner({text: 'You have an investment that is currently being processed by the Credibila Team for this opportunity. You can raise another investment request once the funds have been allocated to you or investment request is closed by the Credibila Team', variant: 'error-banner'}))
            navigate('/dashboard')
            return
          } else if (
            userInvestmentLatest.status === 'PENDING' ||
            (opportunity &&
              opportunity?.ticketSize &&
              userInvestmentDetail &&
              userInvestmentDetail.canInvest &&
              parseInt(opportunity?.ticketSize) <= parseInt(userInvestmentDetail.canInvest))
          ) {
            logCrmEvent(
              EngagementEvents.CLICKED_INVEST_BUTTON,
              {
                Authorization: authToken ?? '',
              },
              {
                opportunityName: opportunity?.projectName,
                opportunityId: opportunity?.id,
                investmentOrderid: userInvestmentLatest.order_id,
              },
            )

            navigate(`/invest/${projectSlug}/${id}`)
            captureEvent(EVENTS.investment_started, {
              projectSlug,
              id,
              userInvestmentDetail,
              project: opportunity,
            }) // Capture the event here
          } else {
            dispatch(
              showBanner({
                text: `Your remaining investment limit is ${
                  displayDollar(userInvestmentDetail.canInvest, opportunity?.currencyCode) || '$0'
                } which is low for the ticket size ${
                  displayDollar(opportunity?.ticketSize, opportunity?.currencyCode) || ''
                }  required to invest in this opportunity. To increase your investment amount please contact the Credibila Team for more information.`,
                variant: 'error-banner',
              }),
            )
            // handleScroll()
          }
        }
      }
    }
  }
  const viewDocumentHandleClick = (legalDocument: string) => {
    window.open(legalDocument, '_blank')
  }
  const backToProjectHandler = () => navigate('/opportunities')

  const handleSimilarProjectClick = (projectSlug: string, id: number) => {
    window.scrollTo({
      top: 0,
    })
    navigate(`/opportunity/${projectSlug}/${id}`, { replace: true })
  }
  const handleClickModal = () => {
    setContactUsModal((state) => !state)
  }

  useEffect(() => {
    const getUserInvestments = async () => {
      // const response: any = await InvestServices.getUserInvestmentDetail({
      //   Authorization: authToken ? authToken : '',
      // });
      const response: any = await investServices.getUserInvestmentDetail_v1({
        Authorization: authToken ? authToken : '',
      })
      if (response && response.data && response.status === 200) {
        setUserInvestmentDetail(response.data)
      }
    }
    const getUserInvestmentLatest = async () => {
      // const response: any = await InvestServices.getInvestmentLatest(
      //   { projectId: id ? parseInt(id) : 0 },
      //   {
      //     Authorization: authToken ? authToken : '',
      //   }
      // );
      const response: any = await investServices.getInvestmentLatest_v1(
        { opportunityId: id ? parseInt(id) : 0 },
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (response && response.data && response.status === 200) {
        setUserInvestmentLatest(response.data)
      }
    }
    const getKYC = async () => {
      // const response: any = await kycServices.getKYC(userId.toString(), {
      //   Authorization: authToken ? authToken : '',
      // });
      const response: any = await kycServices.getKYC_v1(userId.toString(), {
        Authorization: authToken ? authToken : '',
      })
      if (!!response && response.data && response.status === 200) {
        if (response.data.status) {
          dispatch(
            updateConfiguration({
              name: 'kycStatus',
              value: response.data.status,
            }),
          )
        }
      }
    }
    if (authToken) {
      getKYC()
      getUserInvestments()
      getUserInvestmentLatest()
    }
  }, [])

  useEffect(() => {
    const handleInvestButtonProperty = () => {
      if (
        opportunity?.closingDate &&
        calculateRemainingDays(new Date(), new Date(opportunity?.closingDate)) <= 0
      ) {
        setInvestButtonProperty({
          ...investButtonProperty,
          buttonText: 'Closed for investment',
          isDisable: true,
          investmentInfo: (
            <>
              This opportunity has been closed for investment. You can not invest in this
              opportunity. Please contact the{' '}
              <span onClick={() => setContactUsModal(true)}>
                &nbsp;
                {<Anchor variant='basic-anchor' href='' text='Credibila Team' />}
                &nbsp;
              </span>{' '}
              for more information.
            </>
          ),
        })
      }
      if (
        opportunity?.closingDate &&
        calculateRemainingDays(new Date(), new Date(opportunity?.closingDate)) > 0
      ) {
        if (
          kycStatus &&
          (kycStatus === 'NOT_STARTED' || kycStatus === 'INITIATED' || kycStatus === 'RESUBMIT')
        ) {
          setInvestButtonProperty({
            ...investButtonProperty,
            buttonText: 'Complete the KYC',
            investmentInfo: (
              <>
                Please complete the KYC to proceed with the investment. Go to the &nbsp;
                {<Anchor variant='basic-anchor' href='/profile/kyc' text='KYC' />} &nbsp; section.
              </>
            ),
          })
        }
        if (kycStatus && kycStatus === 'PENDING') {
          setInvestButtonProperty({
            ...investButtonProperty,
            isDisable: true,
            buttonText: 'Invest',
            investmentInfo: (
              <>
                The KYC is awaiting Credibila team approval. KYC approval is mandatory for investing
                in the opportunities. Go to the&nbsp;
                {<Anchor variant='basic-anchor' href='/profile/kyc' text='KYC' />} &nbsp; section.
              </>
            ),
          })
        }
        if (kycStatus && kycStatus === 'REJECTED') {
          setInvestButtonProperty({
            ...investButtonProperty,
            isDisable: true,
            buttonText: 'Invest',
            investmentInfo: (
              <>
                Your KYC was rejected, please go to the&nbsp;
                {<Anchor variant='basic-anchor' href='/profile/kyc' text='KYC' />}
                &nbsp; section for more details.
              </>
            ),
          })
        }
        if (kycStatus && kycStatus === 'APPROVED') {
          if (userInvestmentDetail && !(userInvestmentDetail.canInvest > 0)) {
            setInvestButtonProperty({
              ...investButtonProperty,
              isDisable: true,
              buttonText: 'Invest',
              investmentInfo: (
                <>
                  You are not yet assigned an investment amount limit by the Credibila Team to
                  invest in the opportunities. The investment limit is assigned based on your
                  information collected during KYC. Please contact the{' '}
                  <span onClick={() => setContactUsModal(true)}>
                    &nbsp;
                    {<Anchor variant='basic-anchor' href='' text='Credibila Team' />}
                    &nbsp;
                  </span>{' '}
                  for more information.
                </>
              ),
            })
          } else if (
            userInvestmentLatest &&
            userInvestmentLatest.status !== 'PENDING' &&
            opportunity &&
            opportunity.ticketSize &&
            userInvestmentDetail &&
            userInvestmentDetail.canInvest
          ) {
            const remainingRaiseGoalAmount =
              parseInt(opportunity?.raiseGoal) - parseInt(opportunity?.committedAmount)
            if (remainingRaiseGoalAmount < parseInt(opportunity?.ticketSize)) {
              setInvestButtonProperty({
                ...investButtonProperty,
                isDisable: true,
                buttonText: 'Invest',
                // eslint-disable-next-line react/no-unescaped-entities
                investmentInfo: (
                  <>
                    The opportunity is about to reach it&apos;s raise goal value of{' '}
                    {displayDollar(opportunity?.raiseGoal, opportunity?.currencyCode) || ''} and
                    will be closing soon. Please contact the{' '}
                    <span onClick={() => setContactUsModal(true)}>
                      &nbsp;
                      {<Anchor variant='basic-anchor' href='' text='Credibila Team' />}
                      &nbsp;
                    </span>{' '}
                    in case you still want to invest in this opportunity.
                  </>
                ),
              })
            } else if (
              parseInt(opportunity?.ticketSize) > parseInt(userInvestmentDetail.canInvest)
            ) {
              setInvestButtonProperty({
                ...investButtonProperty,
                isDisable: true,
                buttonText: 'Invest',
                investmentInfo: (
                  <>
                    You have used up your investment limit of{' '}
                    {displayDollar(
                      userInvestmentDetail.investmentLimit,
                      opportunity?.currencyCode,
                    ) || ''}{' '}
                    and cannot invest in the opportunity. To increase your investment amount please
                    contact the{' '}
                    <span onClick={() => setContactUsModal(true)}>
                      &nbsp;
                      {<Anchor variant='basic-anchor' href='' text='Credibila Team' />}
                      &nbsp;
                    </span>{' '}
                    for more information.
                  </>
                ),
              })
            }
          }
          if (userInvestmentLatest && userInvestmentLatest.status === 'PENDING') {
            setInvestButtonProperty({
              ...investButtonProperty,
              isDisable: false,
              buttonText: 'Ongoing Investment',
              investmentInfo: (
                <>
                  Complete the ongoing Investment. Please contact the{' '}
                  <span onClick={() => setContactUsModal(true)}>
                    &nbsp;
                    {<Anchor variant='basic-anchor' href='' text='Credibila Team' />}
                    &nbsp;
                  </span>{' '}
                  for more information.
                </>
              ),
            })
          }
        }
      }
      if (userInvestmentLatest && userInvestmentLatest.status === 'INITIATED') {
        setInvestButtonProperty({
          ...investButtonProperty,
          isDisable: false,
          buttonText: 'Ongoing Investment',
          investmentInfo: (
            <>
              You have an investment that is currently being processed by the Credibila Team for
              this opportunity. You can raise another investment request once the funds have been
              allocated to you or investment request is closed by the Credibila Team. Please contact
              the{' '}
              <span onClick={() => setContactUsModal(true)}>
                &nbsp;
                {<Anchor variant='basic-anchor' href='' text='Credibila Team' />}
                &nbsp;
              </span>{' '}
              for more information.
            </>
          ),
        })
      }
    }
    handleInvestButtonProperty()
  }, [opportunity, userInvestmentLatest, kycStatus])

  useEffect(() => {
    const fetchProjectById = async () => {
      const data: any = await projectServices.getOpportunityById(id as string, {
        Authorization: authToken ? authToken : '',
      })
      if (data && data.data && data.status === 200) {
        const opportunity = data.data
        setOpportunity(opportunity)
        updateCrm('last_opportunity_view_date', Date.now().toString(), {
          Authorization: authToken ?? '',
        })
        logCrmEvent(
          EngagementEvents.VISITED_OPPORTUNITY,
          {
            Authorization: authToken ?? '',
          },
          { opportunityName: opportunity?.projectName, opportunityId: opportunity?.id },
        )
      } else navigate('/opportunities')
    }
    if (id !== 'undefined' && projectSlug !== 'undefined') fetchProjectById()
    else navigate('/')
  }, [isSaveForLater, id, projectSlug])

  return (
    <>
      {opportunity && (
        <OpportunityDetailPresenter
          handleSimilarProjectClick={handleSimilarProjectClick}
          project={opportunity}
          similarProjects={similarProjects}
          investHandleClick={investHandleClick}
          saveForLaterHandleClick={saveForLaterHandleClick}
          viewDocumentHandleClick={viewDocumentHandleClick}
          backToProjectHandler={backToProjectHandler}
          userInvestmentLatest={userInvestmentLatest}
          showContactusModal={showContactusModal}
          handleClickModal={handleClickModal}
          contactUsRef={contactUsRef}
          investButtonProperty={investButtonProperty}
          kycStatus={kycStatus}
        />
      )}
    </>
  )
}

export { OpportunityDetailContainer }
